<template>
  <section id="Contact">
  	<v-footer dark padless class="footer-gradient">
  		<v-row justify="center" align="end" class="my-9">
        <v-col cols="12" class="text-center d-inline-flex justify-center py-0">
          <div class="footer-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}" style="margin-top: 0px;">
            UNA APLICACIÓN DE
          </div>
            <a href="http://audituxinformatica.com" class="ma-0 pa-0"><img :src="require('@/assets/Home/AudituxLogo.svg')" alt="" :width="auditux.width" :height="auditux.height"></a>
          <div class="footer-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}" style="margin-top: 0px;">
           INFORMÁTICA</div>
        </v-col>
      </v-row>
  	</v-footer>
  </section>
</template>
<script>
  export default {
    name: 'Footer',
    computed:{
    	 auditux(){
        return {
          width: this.$vuetify.breakpoint.xl ? '152' : this.$vuetify.breakpoint.mdAndUp ? '90' :  '56',
          height: this.$vuetify.breakpoint.xl ?  '30' : this.$vuetify.breakpoint.mdAndUp ? '18' : '12',
        }
      }
    }
  }
</script>
<style lang="sass" scope>

.footer-gradient 
  background: linear-gradient(0deg,#1E222B,#282F37) !important
  border: none !important
	
.footer-landing
  font-weight: 400
  font-family: Roboto, sans-serif !important
  &.lg-view 
    font-size: 1rem !important
    letter-spacing: 0.1em !important
  &.sm-view 
    font-size: 0.625rem !important
  &.xl-view 
    font-size: 1.5rem !important
    letter-spacing: 0.2em !important
</style>